:root {
  --blue-100: rgba(219, 234, 254, 1);
  --blue-200: rgba(191, 219, 254, 1);
  --blue-300: rgba(147, 197, 253, 1);
  --blue-400: rgba(96, 165, 250, 1);
  --blue-50: rgba(239, 246, 255, 1);
  --blue-500: rgba(59, 130, 246, 1);
  --blue-600: rgba(37, 99, 235, 1);
  --blue-700: rgba(29, 78, 216, 1);
  --blue-800: rgba(30, 64, 175, 1);
  --blue-900: rgba(30, 58, 138, 1);
  --gray-100: rgba(243, 244, 246, 1);
  --gray-200: rgba(229, 231, 235, 1);
  --gray-300: rgba(210, 213, 218, 1);
  --gray-400: rgba(156, 163, 175, 1);
  --gray-50: rgba(249, 250, 251, 1);
  --gray-500: rgba(109, 114, 128, 1);
  --gray-600: rgba(75, 85, 99, 1);
  --gray-700: rgba(55, 65, 81, 1);
  --gray-800: rgba(31, 41, 55, 1);
  --gray-900: rgba(17, 24, 39, 1);
  --green-100: rgba(220, 252, 231, 1);
  --green-200: rgba(187, 247, 208, 1);
  --green-300: rgba(134, 239, 172, 1);
  --green-400: rgba(74, 222, 128, 1);
  --green-50: rgba(240, 253, 244, 1);
  --green-500: rgba(34, 197, 94, 1);
  --green-600: rgba(22, 163, 74, 1);
  --green-700: rgba(21, 128, 61, 1);
  --green-800: rgba(22, 101, 52, 1);
  --green-900: rgba(20, 83, 45, 1);
  --indigo-100: rgba(224, 231, 255, 1);
  --indigo-200: rgba(199, 210, 254, 1);
  --indigo-300: rgba(165, 180, 252, 1);
  --indigo-400: rgba(129, 140, 248, 1);
  --indigo-50: rgba(238, 242, 255, 1);
  --indigo-500: rgba(99, 102, 241, 1);
  --indigo-600: rgba(79, 70, 229, 1);
  --indigo-700: rgba(67, 56, 202, 1);
  --indigo-800: rgba(55, 48, 163, 1);
  --indigo-900: rgba(49, 46, 129, 1);
  --orange-100: rgba(255, 237, 213, 1);
  --orange-200: rgba(254, 215, 170, 1);
  --orange-300: rgba(253, 186, 116, 1);
  --orange-400: rgba(251, 146, 60, 1);
  --orange-50: rgba(255, 247, 237, 1);
  --orange-500: rgba(249, 115, 22, 1);
  --orange-600: rgba(234, 88, 12, 1);
  --orange-700: rgba(194, 65, 12, 1);
  --orange-800: rgba(154, 52, 18, 1);
  --orange-900: rgba(124, 45, 18, 1);
  --pink-100: rgba(252, 231, 243, 1);
  --pink-200: rgba(251, 207, 232, 1);
  --pink-300: rgba(249, 168, 212, 1);
  --pink-400: rgba(244, 114, 182, 1);
  --pink-50: rgba(253, 242, 248, 1);
  --pink-500: rgba(236, 72, 153, 1);
  --pink-600: rgba(219, 39, 119, 1);
  --pink-700: rgba(179, 24, 91, 1);
  --pink-800: rgba(157, 23, 77, 1);
  --pink-900: rgba(131, 24, 67, 1);
  --purple-100: rgba(243, 232, 255, 1);
  --purple-200: rgba(233, 213, 255, 1);
  --purple-300: rgba(216, 180, 254, 1);
  --purple-400: rgba(192, 132, 252, 1);
  --purple-50: rgba(250, 245, 255, 1);
  --purple-500: rgba(168, 85, 247, 1);
  --purple-600: rgba(147, 51, 234, 1);
  --purple-700: rgba(126, 34, 206, 1);
  --purple-800: rgba(107, 33, 168, 1);
  --purple-900: rgba(88, 28, 135, 1);
  --red-100: rgba(254, 226, 226, 1);
  --red-200: rgba(254, 202, 202, 1);
  --red-300: rgba(252, 165, 165, 1);
  --red-400: rgba(248, 113, 113, 1);
  --red-50: rgba(254, 242, 242, 1);
  --red-500: rgba(239, 68, 68, 1);
  --red-600: rgba(220, 38, 38, 1);
  --red-700: rgba(185, 28, 28, 1);
  --red-800: rgba(153, 27, 27, 1);
  --red-900: rgba(127, 29, 29, 1);
  --shadow-2xl: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  --shadow-base: 0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
  --shadow-md: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.5);
  --shadow-sm: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
  --shadow-xl: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px 0px rgba(0, 0, 0, 0.04);
  --text-2xl-font-bold-font-family: "Inter-Bold", Helvetica;
  --text-2xl-font-bold-font-size: 24px;
  --text-2xl-font-bold-font-style: normal;
  --text-2xl-font-bold-font-weight: 700;
  --text-2xl-font-bold-letter-spacing: 0px;
  --text-2xl-font-bold-line-height: 150%;
  --text-2xl-font-light-font-family: "Inter-Light", Helvetica;
  --text-2xl-font-light-font-size: 24px;
  --text-2xl-font-light-font-style: normal;
  --text-2xl-font-light-font-weight: 300;
  --text-2xl-font-light-letter-spacing: 0px;
  --text-2xl-font-light-line-height: 150%;
  --text-2xl-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-2xl-font-regular-font-size: 24px;
  --text-2xl-font-regular-font-style: normal;
  --text-2xl-font-regular-font-weight: 400;
  --text-2xl-font-regular-letter-spacing: 0px;
  --text-2xl-font-regular-line-height: 150%;
  --text-2xl-font-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-2xl-font-semibold-font-size: 24px;
  --text-2xl-font-semibold-font-style: normal;
  --text-2xl-font-semibold-font-weight: 600;
  --text-2xl-font-semibold-letter-spacing: 0px;
  --text-2xl-font-semibold-line-height: 150%;
  --text-3xl-font-bold-font-family: "Inter-Bold", Helvetica;
  --text-3xl-font-bold-font-size: 30px;
  --text-3xl-font-bold-font-style: normal;
  --text-3xl-font-bold-font-weight: 700;
  --text-3xl-font-bold-letter-spacing: 0px;
  --text-3xl-font-bold-line-height: 150%;
  --text-3xl-font-light-font-family: "Inter-Light", Helvetica;
  --text-3xl-font-light-font-size: 30px;
  --text-3xl-font-light-font-style: normal;
  --text-3xl-font-light-font-weight: 300;
  --text-3xl-font-light-letter-spacing: 0px;
  --text-3xl-font-light-line-height: 150%;
  --text-3xl-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-3xl-font-regular-font-size: 30px;
  --text-3xl-font-regular-font-style: normal;
  --text-3xl-font-regular-font-weight: 400;
  --text-3xl-font-regular-letter-spacing: 0px;
  --text-3xl-font-regular-line-height: 150%;
  --text-3xl-font-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-3xl-font-semibold-font-size: 30px;
  --text-3xl-font-semibold-font-style: normal;
  --text-3xl-font-semibold-font-weight: 600;
  --text-3xl-font-semibold-letter-spacing: 0px;
  --text-3xl-font-semibold-line-height: 150%;
  --text-4xl-font-bold-font-family: "Inter-Bold", Helvetica;
  --text-4xl-font-bold-font-size: 36px;
  --text-4xl-font-bold-font-style: normal;
  --text-4xl-font-bold-font-weight: 700;
  --text-4xl-font-bold-letter-spacing: 0px;
  --text-4xl-font-bold-line-height: 150%;
  --text-4xl-font-light-font-family: "Inter-Light", Helvetica;
  --text-4xl-font-light-font-size: 36px;
  --text-4xl-font-light-font-style: normal;
  --text-4xl-font-light-font-weight: 300;
  --text-4xl-font-light-letter-spacing: 0px;
  --text-4xl-font-light-line-height: 150%;
  --text-4xl-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-4xl-font-regular-font-size: 36px;
  --text-4xl-font-regular-font-style: normal;
  --text-4xl-font-regular-font-weight: 400;
  --text-4xl-font-regular-letter-spacing: 0px;
  --text-4xl-font-regular-line-height: 150%;
  --text-4xl-font-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-4xl-font-semibold-font-size: 36px;
  --text-4xl-font-semibold-font-style: normal;
  --text-4xl-font-semibold-font-weight: 600;
  --text-4xl-font-semibold-letter-spacing: 0px;
  --text-4xl-font-semibold-line-height: 150%;
  --text-5xl-font-bold-font-family: "Inter-Bold", Helvetica;
  --text-5xl-font-bold-font-size: 48px;
  --text-5xl-font-bold-font-style: normal;
  --text-5xl-font-bold-font-weight: 700;
  --text-5xl-font-bold-letter-spacing: 0px;
  --text-5xl-font-bold-line-height: 139.9999976158142%;
  --text-5xl-font-light-font-family: "Inter-Light", Helvetica;
  --text-5xl-font-light-font-size: 48px;
  --text-5xl-font-light-font-style: normal;
  --text-5xl-font-light-font-weight: 300;
  --text-5xl-font-light-letter-spacing: 0px;
  --text-5xl-font-light-line-height: 139.9999976158142%;
  --text-5xl-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-5xl-font-regular-font-size: 48px;
  --text-5xl-font-regular-font-style: normal;
  --text-5xl-font-regular-font-weight: 400;
  --text-5xl-font-regular-letter-spacing: 0px;
  --text-5xl-font-regular-line-height: 139.9999976158142%;
  --text-5xl-font-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-5xl-font-semibold-font-size: 48px;
  --text-5xl-font-semibold-font-style: normal;
  --text-5xl-font-semibold-font-weight: 600;
  --text-5xl-font-semibold-letter-spacing: 0px;
  --text-5xl-font-semibold-line-height: 139.9999976158142%;
  --text-6xl-font-bold-font-family: "Inter-Bold", Helvetica;
  --text-6xl-font-bold-font-size: 60px;
  --text-6xl-font-bold-font-style: normal;
  --text-6xl-font-bold-font-weight: 700;
  --text-6xl-font-bold-letter-spacing: 0px;
  --text-6xl-font-bold-line-height: 139.9999976158142%;
  --text-6xl-font-light-font-family: "Inter-Light", Helvetica;
  --text-6xl-font-light-font-size: 60px;
  --text-6xl-font-light-font-style: normal;
  --text-6xl-font-light-font-weight: 300;
  --text-6xl-font-light-letter-spacing: 0px;
  --text-6xl-font-light-line-height: 139.9999976158142%;
  --text-6xl-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-6xl-font-regular-font-size: 60px;
  --text-6xl-font-regular-font-style: normal;
  --text-6xl-font-regular-font-weight: 400;
  --text-6xl-font-regular-letter-spacing: 0px;
  --text-6xl-font-regular-line-height: 139.9999976158142%;
  --text-6xl-font-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-6xl-font-semibold-font-size: 60px;
  --text-6xl-font-semibold-font-style: normal;
  --text-6xl-font-semibold-font-weight: 600;
  --text-6xl-font-semibold-letter-spacing: 0px;
  --text-6xl-font-semibold-line-height: 139.9999976158142%;
  --text-7xl-font-bold-font-family: "Inter-Bold", Helvetica;
  --text-7xl-font-bold-font-size: 72px;
  --text-7xl-font-bold-font-style: normal;
  --text-7xl-font-bold-font-weight: 700;
  --text-7xl-font-bold-letter-spacing: 0px;
  --text-7xl-font-bold-line-height: 139.9999976158142%;
  --text-7xl-font-light-font-family: "Inter-Light", Helvetica;
  --text-7xl-font-light-font-size: 72px;
  --text-7xl-font-light-font-style: normal;
  --text-7xl-font-light-font-weight: 300;
  --text-7xl-font-light-letter-spacing: 0px;
  --text-7xl-font-light-line-height: 139.9999976158142%;
  --text-7xl-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-7xl-font-regular-font-size: 72px;
  --text-7xl-font-regular-font-style: normal;
  --text-7xl-font-regular-font-weight: 400;
  --text-7xl-font-regular-letter-spacing: 0px;
  --text-7xl-font-regular-line-height: 139.9999976158142%;
  --text-7xl-font-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-7xl-font-semibold-font-size: 72px;
  --text-7xl-font-semibold-font-style: normal;
  --text-7xl-font-semibold-font-weight: 600;
  --text-7xl-font-semibold-letter-spacing: 0px;
  --text-7xl-font-semibold-line-height: 139.9999976158142%;
  --text-8xl-bold-font-family: "Inter-Bold", Helvetica;
  --text-8xl-bold-font-size: 96px;
  --text-8xl-bold-font-style: normal;
  --text-8xl-bold-font-weight: 700;
  --text-8xl-bold-letter-spacing: 0px;
  --text-8xl-bold-line-height: 129.99999523162842%;
  --text-8xl-font-light-font-family: "Inter-Light", Helvetica;
  --text-8xl-font-light-font-size: 96px;
  --text-8xl-font-light-font-style: normal;
  --text-8xl-font-light-font-weight: 300;
  --text-8xl-font-light-letter-spacing: 0px;
  --text-8xl-font-light-line-height: 129.99999523162842%;
  --text-8xl-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-8xl-font-regular-font-size: 96px;
  --text-8xl-font-regular-font-style: normal;
  --text-8xl-font-regular-font-weight: 400;
  --text-8xl-font-regular-letter-spacing: 0px;
  --text-8xl-font-regular-line-height: 129.99999523162842%;
  --text-8xl-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-8xl-semibold-font-size: 96px;
  --text-8xl-semibold-font-style: normal;
  --text-8xl-semibold-font-weight: 600;
  --text-8xl-semibold-letter-spacing: 0px;
  --text-8xl-semibold-line-height: 129.99999523162842%;
  --text-9xl-font-bold-font-family: "Inter-Bold", Helvetica;
  --text-9xl-font-bold-font-size: 128px;
  --text-9xl-font-bold-font-style: normal;
  --text-9xl-font-bold-font-weight: 700;
  --text-9xl-font-bold-letter-spacing: 0px;
  --text-9xl-font-bold-line-height: 129.99999523162842%;
  --text-9xl-font-light-font-family: "Inter-Light", Helvetica;
  --text-9xl-font-light-font-size: 128px;
  --text-9xl-font-light-font-style: normal;
  --text-9xl-font-light-font-weight: 300;
  --text-9xl-font-light-letter-spacing: 0px;
  --text-9xl-font-light-line-height: 129.99999523162842%;
  --text-9xl-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-9xl-font-regular-font-size: 128px;
  --text-9xl-font-regular-font-style: normal;
  --text-9xl-font-regular-font-weight: 400;
  --text-9xl-font-regular-letter-spacing: 0px;
  --text-9xl-font-regular-line-height: 129.99999523162842%;
  --text-9xl-font-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-9xl-font-semibold-font-size: 128px;
  --text-9xl-font-semibold-font-style: normal;
  --text-9xl-font-semibold-font-weight: 600;
  --text-9xl-font-semibold-letter-spacing: 0px;
  --text-9xl-font-semibold-line-height: 129.99999523162842%;
  --text-base-font-bold-font-family: "Inter-Bold", Helvetica;
  --text-base-font-bold-font-size: 16px;
  --text-base-font-bold-font-style: normal;
  --text-base-font-bold-font-weight: 700;
  --text-base-font-bold-letter-spacing: 0px;
  --text-base-font-bold-line-height: 150%;
  --text-base-font-light-font-family: "Inter-Light", Helvetica;
  --text-base-font-light-font-size: 16px;
  --text-base-font-light-font-style: normal;
  --text-base-font-light-font-weight: 300;
  --text-base-font-light-letter-spacing: 0px;
  --text-base-font-light-line-height: 150%;
  --text-base-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-base-font-regular-font-size: 16px;
  --text-base-font-regular-font-style: normal;
  --text-base-font-regular-font-weight: 400;
  --text-base-font-regular-letter-spacing: 0px;
  --text-base-font-regular-line-height: 150%;
  --text-base-font-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-base-font-semibold-font-size: 16px;
  --text-base-font-semibold-font-style: normal;
  --text-base-font-semibold-font-weight: 600;
  --text-base-font-semibold-letter-spacing: 0px;
  --text-base-font-semibold-line-height: 150%;
  --text-lg-font-bold-font-family: "Inter-Bold", Helvetica;
  --text-lg-font-bold-font-size: 18px;
  --text-lg-font-bold-font-style: normal;
  --text-lg-font-bold-font-weight: 700;
  --text-lg-font-bold-letter-spacing: 0px;
  --text-lg-font-bold-line-height: 150%;
  --text-lg-font-light-font-family: "Inter-Light", Helvetica;
  --text-lg-font-light-font-size: 18px;
  --text-lg-font-light-font-style: normal;
  --text-lg-font-light-font-weight: 300;
  --text-lg-font-light-letter-spacing: 0px;
  --text-lg-font-light-line-height: 150%;
  --text-lg-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-lg-font-regular-font-size: 18px;
  --text-lg-font-regular-font-style: normal;
  --text-lg-font-regular-font-weight: 400;
  --text-lg-font-regular-letter-spacing: 0px;
  --text-lg-font-regular-line-height: 150%;
  --text-lg-font-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-lg-font-semibold-font-size: 18px;
  --text-lg-font-semibold-font-style: normal;
  --text-lg-font-semibold-font-weight: 600;
  --text-lg-font-semibold-letter-spacing: 0px;
  --text-lg-font-semibold-line-height: 150%;
  --text-sm-font-bold-font-family: "Inter-Bold", Helvetica;
  --text-sm-font-bold-font-size: 14px;
  --text-sm-font-bold-font-style: normal;
  --text-sm-font-bold-font-weight: 700;
  --text-sm-font-bold-letter-spacing: 0px;
  --text-sm-font-bold-line-height: 129.99999523162842%;
  --text-sm-font-light-font-family: "Inter-Light", Helvetica;
  --text-sm-font-light-font-size: 14px;
  --text-sm-font-light-font-style: normal;
  --text-sm-font-light-font-weight: 300;
  --text-sm-font-light-letter-spacing: 0px;
  --text-sm-font-light-line-height: 129.99999523162842%;
  --text-sm-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-sm-font-regular-font-size: 14px;
  --text-sm-font-regular-font-style: normal;
  --text-sm-font-regular-font-weight: 400;
  --text-sm-font-regular-letter-spacing: 0px;
  --text-sm-font-regular-line-height: 129.99999523162842%;
  --text-sm-font-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-sm-font-semibold-font-size: 14px;
  --text-sm-font-semibold-font-style: normal;
  --text-sm-font-semibold-font-weight: 600;
  --text-sm-font-semibold-letter-spacing: 0px;
  --text-sm-font-semibold-line-height: 20px;
  --text-xl-font-bold-font-family: "Inter-Bold", Helvetica;
  --text-xl-font-bold-font-size: 20px;
  --text-xl-font-bold-font-style: normal;
  --text-xl-font-bold-font-weight: 700;
  --text-xl-font-bold-letter-spacing: 0px;
  --text-xl-font-bold-line-height: 150%;
  --text-xl-font-light-font-family: "Inter-Light", Helvetica;
  --text-xl-font-light-font-size: 20px;
  --text-xl-font-light-font-style: normal;
  --text-xl-font-light-font-weight: 300;
  --text-xl-font-light-letter-spacing: 0px;
  --text-xl-font-light-line-height: 150%;
  --text-xl-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-xl-font-regular-font-size: 20px;
  --text-xl-font-regular-font-style: normal;
  --text-xl-font-regular-font-weight: 400;
  --text-xl-font-regular-letter-spacing: 0px;
  --text-xl-font-regular-line-height: 150%;
  --text-xl-font-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-xl-font-semibold-font-size: 20px;
  --text-xl-font-semibold-font-style: normal;
  --text-xl-font-semibold-font-weight: 600;
  --text-xl-font-semibold-letter-spacing: 0px;
  --text-xl-font-semibold-line-height: 150%;
  --text-xs-font-bold-font-family: "Inter-Bold", Helvetica;
  --text-xs-font-bold-font-size: 12px;
  --text-xs-font-bold-font-style: normal;
  --text-xs-font-bold-font-weight: 700;
  --text-xs-font-bold-letter-spacing: 0px;
  --text-xs-font-bold-line-height: 129.99999523162842%;
  --text-xs-font-light-font-family: "Inter-Light", Helvetica;
  --text-xs-font-light-font-size: 12px;
  --text-xs-font-light-font-style: normal;
  --text-xs-font-light-font-weight: 300;
  --text-xs-font-light-letter-spacing: 0px;
  --text-xs-font-light-line-height: 129.99999523162842%;
  --text-xs-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-xs-font-regular-font-size: 12px;
  --text-xs-font-regular-font-style: normal;
  --text-xs-font-regular-font-weight: 400;
  --text-xs-font-regular-letter-spacing: 0px;
  --text-xs-font-regular-line-height: 129.99999523162842%;
  --text-xs-font-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-xs-font-semibold-font-size: 12px;
  --text-xs-font-semibold-font-style: normal;
  --text-xs-font-semibold-font-weight: 600;
  --text-xs-font-semibold-letter-spacing: 0px;
  --text-xs-font-semibold-line-height: 129.99999523162842%;
  --white: rgba(255, 255, 255, 1);
  --yellow-100: rgba(254, 249, 195, 1);
  --yellow-200: rgba(254, 240, 138, 1);
  --yellow-300: rgba(253, 224, 71, 1);
  --yellow-400: rgba(250, 204, 21, 1);
  --yellow-50: rgba(254, 252, 232, 1);
  --yellow-500: rgba(234, 179, 8, 1);
  --yellow-600: rgba(202, 138, 4, 1);
  --yellow-700: rgba(161, 98, 7, 1);
  --yellow-800: rgba(133, 77, 14, 1);
  --yellow-900: rgba(113, 63, 18, 1);
}
