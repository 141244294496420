#app {
  display: flex;
  justify-content: center;
}

.concept {
  align-items: center;
  border: 1px none;
  display: inline-flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  /* zoom: 0.5; */
}

.concept .div {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 80px;
  padding: 80px 112px;
  position: relative;
}

.concept .frame {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  justify-content: center;
  position: relative;
  width: 695px;
}

.concept .header {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  margin-left: -54.5px;
  margin-right: -54.5px;
  position: relative;
  width: 804px;
}

.concept .text-wrapper {
  align-self: stretch;
  color: #111827;
  font-family: "Nanum Myeongjo", Helvetica;
  font-size: 48px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 46px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.concept .title {
  align-self: stretch;
  color: #111827;
  font-family: "Nanum Myeongjo", Helvetica;
  font-size: 46px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 46px;
  position: relative;
  text-align: center;
}

.concept .title-2 {
  align-self: stretch;
  color: #111827;
  font-family: "Noto Sans KR", Helvetica;
  font-size: 80px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 16px;
  position: relative;
  text-align: center;
}

.concept .title-3 {
  align-self: stretch;
  color: #111827;
  font-family: "Nanum Myeongjo", Helvetica;
  font-size: 90px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 90px;
  position: relative;
  text-align: center;
}

.concept .images {
  background-color: #ffffff;
  background-image: url(../../../static/img/ljs-0004-1.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 20px;
  height: 1200px;
  overflow: hidden;
  position: relative;
  width: 800px;
}

.concept .talkmedia-i {
  height: 1200px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 800px;
}

.concept .text {
  align-self: stretch;
  color: #111827;
  font-family: "Noto Sans KR", Helvetica;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: normal;
  position: relative;
  text-align: center;
}

.concept .div-2 {
  background-color: var(--gray-300);
  height: 1px;
  position: relative;
  width: 1216px;
}

.concept .p {
  align-self: stretch;
  color: #111827;
  font-family: "Nanum Myeongjo", Helvetica;
  font-size: 48px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 67.2px;
  position: relative;
  text-align: center;
}

.concept .span {
  font-weight: 900;
}

.concept .text-wrapper-2 {
  color: #111827;
  font-family: "Nanum Myeongjo", Helvetica;
  font-size: 48px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 67.2px;
}

.concept .frame-2 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  justify-content: center;
  position: relative;
  width: 590px;
}

.concept .header-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.concept .title-4 {
  align-self: stretch;
  color: #111827;
  font-family: "Noto Sans KR", Helvetica;
  font-size: 48px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 67.2px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.concept .gallery {
  height: 750px;
  margin-left: -425px;
  margin-right: -425px;
  position: relative;
  width: 500px;
  zoom: 2;
}

.concept .area {
  background-color: #484848;
  height: 900px;
  margin-left: -425px;
  margin-right: -425px;
  position: relative;
  width: 900px;
}

.concept .header-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  justify-content: center;
  position: relative;
}

.concept .header-3 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 974px;
}

.concept .text-2 {
  align-self: stretch;
  color: var(--gray-500);
  font-family: "Noto Sans KR", Helvetica;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.concept .title-5 {
  align-self: stretch;
  color: #111827;
  font-family: "Noto Sans KR", Helvetica;
  font-size: 58px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 96px;
  position: relative;
  text-align: center;
}

.concept .map {
  background-color: #484848;
  height: 450px;
  margin-left: -112px;
  margin-right: -112px;
  position: relative;
  width: 450px;
  zoom: 2;
}

.concept .buttons {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.concept .button {
  align-items: center;
  background-color: #20c55f;
  border-radius: 11.1px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 18.5px 37px;
  position: relative;
  width: 262.7px;
}

.concept .text-3 {
  color: var(--white);
  font-family: "Noto Sans KR", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 37px;
  margin-top: -1.85px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.concept .div-wrapper {
  align-items: center;
  background-color: #eab308;
  border-radius: 11.1px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 18.5px 37px;
  position: relative;
  width: 262.7px;
}

.concept .text-4 {
  color: #111827;
  font-family: "Noto Sans KR", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 37px;
  margin-top: -1.85px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.concept .button-2 {
  align-items: center;
  background-color: #4585e4;
  border-radius: 11.1px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 18.5px 37px;
  position: relative;
  width: 262.7px;
}

.concept .header-4 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  margin-left: -168px;
  margin-right: -168px;
  position: relative;
  width: 926px;
}

.concept .row {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 1000px;
}

.concept .feature-card {
  align-items: center;
  background-color: #ffffff;
  border-radius: 6px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 32px 0px;
  position: relative;
  width: 1000px;
}

.concept .icon {
  height: 64px;
  position: relative;
  width: 64px;
}

.concept .title-text {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.concept .text-5 {
  align-self: stretch;
  color: #111827;
  font-family: "Noto Sans KR", Helvetica;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 60px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.concept .text-6 {
  align-self: stretch;
  color: #111827;
  font-family: "Noto Sans KR", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: normal;
  position: relative;
  text-align: center;
}

.concept .text-wrapper-3 {
  font-weight: 500;
}

.concept .text-wrapper-4 {
  font-weight: 700;
}

.concept .image-wrapper {
  background-color: #ffffff;
  border-radius: 6px;
  height: 630px;
  overflow: hidden;
  position: relative;
  width: 815px;
}

.concept .image {
  height: 624px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 815px;
}

.concept .frame-3 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  justify-content: center;
  position: relative;
  width: 755px;
}

.concept .title-6 {
  align-self: stretch;
  color: #111827;
  font-family: "Noto Sans KR", Helvetica;
  font-size: 64px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 96px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.concept .text-7 {
  align-self: stretch;
  color: var(--gray-500);
  font-family: "Noto Sans KR", Helvetica;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: normal;
  position: relative;
  text-align: center;
}

.concept .buttons-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.concept .button-31 {
  align-items: center;
  background-color: var(--blue-800);
  border-radius: 11.1px;
  display: flex;
  height: 90px;
  justify-content: center;
  overflow: hidden;
  padding: 18.5px 37px;
  position: relative;
  width: 815px;
}

.concept .button-32 {
  align-items: center;
  background-color: var(--pink-600);
  border-radius: 11.1px;
  display: flex;
  height: 90px;
  justify-content: center;
  overflow: hidden;
  padding: 18.5px 37px;
  position: relative;
  width: 815px;
}

.concept .text-8 {
  color: var(--white);
  font-family: "Noto Sans KR", Helvetica;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 37px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.concept .button-4 {
  align-items: center;
  background-color: var(--gray-200);
  border-radius: 11.1px;
  display: flex;
  height: 90px;
  justify-content: center;
  overflow: hidden;
  padding: 18.5px 37px;
  position: relative;
  width: 815px;
}

.concept .text-9 {
  color: #111827;
  font-family: "Noto Sans KR", Helvetica;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 37px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.concept .button-5 {
  align-items: center;
  background-color: var(--yellow-500);
  border-radius: 11.1px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 18.5px 37px;
  position: relative;
  width: 262.7px;
}

.concept .text-10 {
  color: #000000;
  font-family: "Noto Sans KR", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 37px;
  margin-top: -1.85px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.concept .button-6 {
  align-items: center;
  background-color: #111827;
  border-radius: 11.1px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 18.5px 37px;
  position: relative;
  width: 262.7px;
}

.concept .text-11 {
  color: var(--white);
  font-family: "Noto Sans KR", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 37px;
  margin-left: -5.15px;
  margin-right: -5.15px;
  margin-top: -1.85px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.concept .text-12 {
  color: var(--white);
  font-family: "Noto Sans KR", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 37px;
  margin-left: -12.65px;
  margin-right: -12.65px;
  margin-top: -1.85px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.concept .footer {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 80px;
  mix-blend-mode: normal;
  padding: 80px 112px;
  position: relative;
}

.concept .content {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.concept .img {
  height: 68.87px;
  position: relative;
  width: 54.05px;
}

.concept .text-13 {
  color: var(--gray-500);
  font-family: "Noto Sans KR", Helvetica;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 31.2px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.buttons div,
.buttons-2 div {
  cursor: pointer;
}
