:root {
  --blue-100: #dbeafe;
  --blue-200: #bfdbfe;
  --blue-300: #93c5fd;
  --blue-400: #60a5fa;
  --blue-50: #eff6ff;
  --blue-500: #3b82f6;
  --blue-600: #2563eb;
  --blue-700: #1d4ed8;
  --blue-800: #1e40af;
  --blue-900: #1e3a8a;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d2d5da;
  --gray-400: #9ca3af;
  --gray-50: #f9fafb;
  --gray-500: #6d7280;
  --gray-600: #4b5563;
  --gray-700: #374151;
  --gray-800: #1f2937;
  --gray-900: #111827;
  --green-100: #dcfce7;
  --green-200: #bbf7d0;
  --green-300: #86efac;
  --green-400: #4ade80;
  --green-50: #f0fdf4;
  --green-500: #22c55e;
  --green-600: #16a34a;
  --green-700: #15803d;
  --green-800: #166534;
  --green-900: #14532d;
  --indigo-100: #e0e7ff;
  --indigo-200: #c7d2fe;
  --indigo-300: #a5b4fc;
  --indigo-400: #818cf8;
  --indigo-50: #eef2ff;
  --indigo-500: #6366f1;
  --indigo-600: #4f46e5;
  --indigo-700: #4338ca;
  --indigo-800: #3730a3;
  --indigo-900: #312e81;
  --orange-100: #ffedd5;
  --orange-200: #fed7aa;
  --orange-300: #fdba74;
  --orange-400: #fb923c;
  --orange-50: #fff7ed;
  --orange-500: #f97316;
  --orange-600: #ea580c;
  --orange-700: #c2410c;
  --orange-800: #9a3412;
  --orange-900: #7c2d12;
  --pink-100: #fce7f3;
  --pink-200: #fbcfe8;
  --pink-300: #f9a8d4;
  --pink-400: #f472b6;
  --pink-50: #fdf2f8;
  --pink-500: #ec4899;
  --pink-600: #db2777;
  --pink-700: #b3185b;
  --pink-800: #9d174d;
  --pink-900: #831843;
  --purple-100: #f3e8ff;
  --purple-200: #e9d5ff;
  --purple-300: #d8b4fe;
  --purple-400: #c084fc;
  --purple-50: #faf5ff;
  --purple-500: #a855f7;
  --purple-600: #9333ea;
  --purple-700: #7e22ce;
  --purple-800: #6b21a8;
  --purple-900: #581c87;
  --red-100: #fee2e2;
  --red-200: #fecaca;
  --red-300: #fca5a5;
  --red-400: #f87171;
  --red-50: #fef2f2;
  --red-500: #ef4444;
  --red-600: #dc2626;
  --red-700: #b91c1c;
  --red-800: #991b1b;
  --red-900: #7f1d1d;
  --shadow-2xl: 0px 25px 50px -12px #00000040;
  --shadow-base: 0px 1px 3px 0px #0000001a, 0px 1px 2px -1px #0000001a;
  --shadow-lg: 0px 10px 15px -3px #0000001a, 0px 4px 6px 0px #0000000d;
  --shadow-md: 0px 4px 6px -1px #0000001a, 0px 2px 4px -2px #00000080;
  --shadow-sm: 0px 1px 2px 0px #00000014;
  --shadow-xl: 0px 20px 25px -5px #0000001a, 0px 10px 10px 0px #0000000a;
  --text-2xl-font-bold-font-family: "Inter-Bold", Helvetica;
  --text-2xl-font-bold-font-size: 24px;
  --text-2xl-font-bold-font-style: normal;
  --text-2xl-font-bold-font-weight: 700;
  --text-2xl-font-bold-letter-spacing: 0px;
  --text-2xl-font-bold-line-height: 150%;
  --text-2xl-font-light-font-family: "Inter-Light", Helvetica;
  --text-2xl-font-light-font-size: 24px;
  --text-2xl-font-light-font-style: normal;
  --text-2xl-font-light-font-weight: 300;
  --text-2xl-font-light-letter-spacing: 0px;
  --text-2xl-font-light-line-height: 150%;
  --text-2xl-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-2xl-font-regular-font-size: 24px;
  --text-2xl-font-regular-font-style: normal;
  --text-2xl-font-regular-font-weight: 400;
  --text-2xl-font-regular-letter-spacing: 0px;
  --text-2xl-font-regular-line-height: 150%;
  --text-2xl-font-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-2xl-font-semibold-font-size: 24px;
  --text-2xl-font-semibold-font-style: normal;
  --text-2xl-font-semibold-font-weight: 600;
  --text-2xl-font-semibold-letter-spacing: 0px;
  --text-2xl-font-semibold-line-height: 150%;
  --text-3xl-font-bold-font-family: "Inter-Bold", Helvetica;
  --text-3xl-font-bold-font-size: 30px;
  --text-3xl-font-bold-font-style: normal;
  --text-3xl-font-bold-font-weight: 700;
  --text-3xl-font-bold-letter-spacing: 0px;
  --text-3xl-font-bold-line-height: 150%;
  --text-3xl-font-light-font-family: "Inter-Light", Helvetica;
  --text-3xl-font-light-font-size: 30px;
  --text-3xl-font-light-font-style: normal;
  --text-3xl-font-light-font-weight: 300;
  --text-3xl-font-light-letter-spacing: 0px;
  --text-3xl-font-light-line-height: 150%;
  --text-3xl-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-3xl-font-regular-font-size: 30px;
  --text-3xl-font-regular-font-style: normal;
  --text-3xl-font-regular-font-weight: 400;
  --text-3xl-font-regular-letter-spacing: 0px;
  --text-3xl-font-regular-line-height: 150%;
  --text-3xl-font-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-3xl-font-semibold-font-size: 30px;
  --text-3xl-font-semibold-font-style: normal;
  --text-3xl-font-semibold-font-weight: 600;
  --text-3xl-font-semibold-letter-spacing: 0px;
  --text-3xl-font-semibold-line-height: 150%;
  --text-4xl-font-bold-font-family: "Inter-Bold", Helvetica;
  --text-4xl-font-bold-font-size: 36px;
  --text-4xl-font-bold-font-style: normal;
  --text-4xl-font-bold-font-weight: 700;
  --text-4xl-font-bold-letter-spacing: 0px;
  --text-4xl-font-bold-line-height: 150%;
  --text-4xl-font-light-font-family: "Inter-Light", Helvetica;
  --text-4xl-font-light-font-size: 36px;
  --text-4xl-font-light-font-style: normal;
  --text-4xl-font-light-font-weight: 300;
  --text-4xl-font-light-letter-spacing: 0px;
  --text-4xl-font-light-line-height: 150%;
  --text-4xl-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-4xl-font-regular-font-size: 36px;
  --text-4xl-font-regular-font-style: normal;
  --text-4xl-font-regular-font-weight: 400;
  --text-4xl-font-regular-letter-spacing: 0px;
  --text-4xl-font-regular-line-height: 150%;
  --text-4xl-font-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-4xl-font-semibold-font-size: 36px;
  --text-4xl-font-semibold-font-style: normal;
  --text-4xl-font-semibold-font-weight: 600;
  --text-4xl-font-semibold-letter-spacing: 0px;
  --text-4xl-font-semibold-line-height: 150%;
  --text-5xl-font-bold-font-family: "Inter-Bold", Helvetica;
  --text-5xl-font-bold-font-size: 48px;
  --text-5xl-font-bold-font-style: normal;
  --text-5xl-font-bold-font-weight: 700;
  --text-5xl-font-bold-letter-spacing: 0px;
  --text-5xl-font-bold-line-height: 140.0%;
  --text-5xl-font-light-font-family: "Inter-Light", Helvetica;
  --text-5xl-font-light-font-size: 48px;
  --text-5xl-font-light-font-style: normal;
  --text-5xl-font-light-font-weight: 300;
  --text-5xl-font-light-letter-spacing: 0px;
  --text-5xl-font-light-line-height: 140.0%;
  --text-5xl-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-5xl-font-regular-font-size: 48px;
  --text-5xl-font-regular-font-style: normal;
  --text-5xl-font-regular-font-weight: 400;
  --text-5xl-font-regular-letter-spacing: 0px;
  --text-5xl-font-regular-line-height: 140.0%;
  --text-5xl-font-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-5xl-font-semibold-font-size: 48px;
  --text-5xl-font-semibold-font-style: normal;
  --text-5xl-font-semibold-font-weight: 600;
  --text-5xl-font-semibold-letter-spacing: 0px;
  --text-5xl-font-semibold-line-height: 140.0%;
  --text-6xl-font-bold-font-family: "Inter-Bold", Helvetica;
  --text-6xl-font-bold-font-size: 60px;
  --text-6xl-font-bold-font-style: normal;
  --text-6xl-font-bold-font-weight: 700;
  --text-6xl-font-bold-letter-spacing: 0px;
  --text-6xl-font-bold-line-height: 140.0%;
  --text-6xl-font-light-font-family: "Inter-Light", Helvetica;
  --text-6xl-font-light-font-size: 60px;
  --text-6xl-font-light-font-style: normal;
  --text-6xl-font-light-font-weight: 300;
  --text-6xl-font-light-letter-spacing: 0px;
  --text-6xl-font-light-line-height: 140.0%;
  --text-6xl-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-6xl-font-regular-font-size: 60px;
  --text-6xl-font-regular-font-style: normal;
  --text-6xl-font-regular-font-weight: 400;
  --text-6xl-font-regular-letter-spacing: 0px;
  --text-6xl-font-regular-line-height: 140.0%;
  --text-6xl-font-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-6xl-font-semibold-font-size: 60px;
  --text-6xl-font-semibold-font-style: normal;
  --text-6xl-font-semibold-font-weight: 600;
  --text-6xl-font-semibold-letter-spacing: 0px;
  --text-6xl-font-semibold-line-height: 140.0%;
  --text-7xl-font-bold-font-family: "Inter-Bold", Helvetica;
  --text-7xl-font-bold-font-size: 72px;
  --text-7xl-font-bold-font-style: normal;
  --text-7xl-font-bold-font-weight: 700;
  --text-7xl-font-bold-letter-spacing: 0px;
  --text-7xl-font-bold-line-height: 140.0%;
  --text-7xl-font-light-font-family: "Inter-Light", Helvetica;
  --text-7xl-font-light-font-size: 72px;
  --text-7xl-font-light-font-style: normal;
  --text-7xl-font-light-font-weight: 300;
  --text-7xl-font-light-letter-spacing: 0px;
  --text-7xl-font-light-line-height: 140.0%;
  --text-7xl-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-7xl-font-regular-font-size: 72px;
  --text-7xl-font-regular-font-style: normal;
  --text-7xl-font-regular-font-weight: 400;
  --text-7xl-font-regular-letter-spacing: 0px;
  --text-7xl-font-regular-line-height: 140.0%;
  --text-7xl-font-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-7xl-font-semibold-font-size: 72px;
  --text-7xl-font-semibold-font-style: normal;
  --text-7xl-font-semibold-font-weight: 600;
  --text-7xl-font-semibold-letter-spacing: 0px;
  --text-7xl-font-semibold-line-height: 140.0%;
  --text-8xl-bold-font-family: "Inter-Bold", Helvetica;
  --text-8xl-bold-font-size: 96px;
  --text-8xl-bold-font-style: normal;
  --text-8xl-bold-font-weight: 700;
  --text-8xl-bold-letter-spacing: 0px;
  --text-8xl-bold-line-height: 130.0%;
  --text-8xl-font-light-font-family: "Inter-Light", Helvetica;
  --text-8xl-font-light-font-size: 96px;
  --text-8xl-font-light-font-style: normal;
  --text-8xl-font-light-font-weight: 300;
  --text-8xl-font-light-letter-spacing: 0px;
  --text-8xl-font-light-line-height: 130.0%;
  --text-8xl-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-8xl-font-regular-font-size: 96px;
  --text-8xl-font-regular-font-style: normal;
  --text-8xl-font-regular-font-weight: 400;
  --text-8xl-font-regular-letter-spacing: 0px;
  --text-8xl-font-regular-line-height: 130.0%;
  --text-8xl-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-8xl-semibold-font-size: 96px;
  --text-8xl-semibold-font-style: normal;
  --text-8xl-semibold-font-weight: 600;
  --text-8xl-semibold-letter-spacing: 0px;
  --text-8xl-semibold-line-height: 130.0%;
  --text-9xl-font-bold-font-family: "Inter-Bold", Helvetica;
  --text-9xl-font-bold-font-size: 128px;
  --text-9xl-font-bold-font-style: normal;
  --text-9xl-font-bold-font-weight: 700;
  --text-9xl-font-bold-letter-spacing: 0px;
  --text-9xl-font-bold-line-height: 130.0%;
  --text-9xl-font-light-font-family: "Inter-Light", Helvetica;
  --text-9xl-font-light-font-size: 128px;
  --text-9xl-font-light-font-style: normal;
  --text-9xl-font-light-font-weight: 300;
  --text-9xl-font-light-letter-spacing: 0px;
  --text-9xl-font-light-line-height: 130.0%;
  --text-9xl-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-9xl-font-regular-font-size: 128px;
  --text-9xl-font-regular-font-style: normal;
  --text-9xl-font-regular-font-weight: 400;
  --text-9xl-font-regular-letter-spacing: 0px;
  --text-9xl-font-regular-line-height: 130.0%;
  --text-9xl-font-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-9xl-font-semibold-font-size: 128px;
  --text-9xl-font-semibold-font-style: normal;
  --text-9xl-font-semibold-font-weight: 600;
  --text-9xl-font-semibold-letter-spacing: 0px;
  --text-9xl-font-semibold-line-height: 130.0%;
  --text-base-font-bold-font-family: "Inter-Bold", Helvetica;
  --text-base-font-bold-font-size: 16px;
  --text-base-font-bold-font-style: normal;
  --text-base-font-bold-font-weight: 700;
  --text-base-font-bold-letter-spacing: 0px;
  --text-base-font-bold-line-height: 150%;
  --text-base-font-light-font-family: "Inter-Light", Helvetica;
  --text-base-font-light-font-size: 16px;
  --text-base-font-light-font-style: normal;
  --text-base-font-light-font-weight: 300;
  --text-base-font-light-letter-spacing: 0px;
  --text-base-font-light-line-height: 150%;
  --text-base-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-base-font-regular-font-size: 16px;
  --text-base-font-regular-font-style: normal;
  --text-base-font-regular-font-weight: 400;
  --text-base-font-regular-letter-spacing: 0px;
  --text-base-font-regular-line-height: 150%;
  --text-base-font-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-base-font-semibold-font-size: 16px;
  --text-base-font-semibold-font-style: normal;
  --text-base-font-semibold-font-weight: 600;
  --text-base-font-semibold-letter-spacing: 0px;
  --text-base-font-semibold-line-height: 150%;
  --text-lg-font-bold-font-family: "Inter-Bold", Helvetica;
  --text-lg-font-bold-font-size: 18px;
  --text-lg-font-bold-font-style: normal;
  --text-lg-font-bold-font-weight: 700;
  --text-lg-font-bold-letter-spacing: 0px;
  --text-lg-font-bold-line-height: 150%;
  --text-lg-font-light-font-family: "Inter-Light", Helvetica;
  --text-lg-font-light-font-size: 18px;
  --text-lg-font-light-font-style: normal;
  --text-lg-font-light-font-weight: 300;
  --text-lg-font-light-letter-spacing: 0px;
  --text-lg-font-light-line-height: 150%;
  --text-lg-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-lg-font-regular-font-size: 18px;
  --text-lg-font-regular-font-style: normal;
  --text-lg-font-regular-font-weight: 400;
  --text-lg-font-regular-letter-spacing: 0px;
  --text-lg-font-regular-line-height: 150%;
  --text-lg-font-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-lg-font-semibold-font-size: 18px;
  --text-lg-font-semibold-font-style: normal;
  --text-lg-font-semibold-font-weight: 600;
  --text-lg-font-semibold-letter-spacing: 0px;
  --text-lg-font-semibold-line-height: 150%;
  --text-sm-font-bold-font-family: "Inter-Bold", Helvetica;
  --text-sm-font-bold-font-size: 14px;
  --text-sm-font-bold-font-style: normal;
  --text-sm-font-bold-font-weight: 700;
  --text-sm-font-bold-letter-spacing: 0px;
  --text-sm-font-bold-line-height: 130.0%;
  --text-sm-font-light-font-family: "Inter-Light", Helvetica;
  --text-sm-font-light-font-size: 14px;
  --text-sm-font-light-font-style: normal;
  --text-sm-font-light-font-weight: 300;
  --text-sm-font-light-letter-spacing: 0px;
  --text-sm-font-light-line-height: 130.0%;
  --text-sm-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-sm-font-regular-font-size: 14px;
  --text-sm-font-regular-font-style: normal;
  --text-sm-font-regular-font-weight: 400;
  --text-sm-font-regular-letter-spacing: 0px;
  --text-sm-font-regular-line-height: 130.0%;
  --text-sm-font-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-sm-font-semibold-font-size: 14px;
  --text-sm-font-semibold-font-style: normal;
  --text-sm-font-semibold-font-weight: 600;
  --text-sm-font-semibold-letter-spacing: 0px;
  --text-sm-font-semibold-line-height: 20px;
  --text-xl-font-bold-font-family: "Inter-Bold", Helvetica;
  --text-xl-font-bold-font-size: 20px;
  --text-xl-font-bold-font-style: normal;
  --text-xl-font-bold-font-weight: 700;
  --text-xl-font-bold-letter-spacing: 0px;
  --text-xl-font-bold-line-height: 150%;
  --text-xl-font-light-font-family: "Inter-Light", Helvetica;
  --text-xl-font-light-font-size: 20px;
  --text-xl-font-light-font-style: normal;
  --text-xl-font-light-font-weight: 300;
  --text-xl-font-light-letter-spacing: 0px;
  --text-xl-font-light-line-height: 150%;
  --text-xl-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-xl-font-regular-font-size: 20px;
  --text-xl-font-regular-font-style: normal;
  --text-xl-font-regular-font-weight: 400;
  --text-xl-font-regular-letter-spacing: 0px;
  --text-xl-font-regular-line-height: 150%;
  --text-xl-font-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-xl-font-semibold-font-size: 20px;
  --text-xl-font-semibold-font-style: normal;
  --text-xl-font-semibold-font-weight: 600;
  --text-xl-font-semibold-letter-spacing: 0px;
  --text-xl-font-semibold-line-height: 150%;
  --text-xs-font-bold-font-family: "Inter-Bold", Helvetica;
  --text-xs-font-bold-font-size: 12px;
  --text-xs-font-bold-font-style: normal;
  --text-xs-font-bold-font-weight: 700;
  --text-xs-font-bold-letter-spacing: 0px;
  --text-xs-font-bold-line-height: 130.0%;
  --text-xs-font-light-font-family: "Inter-Light", Helvetica;
  --text-xs-font-light-font-size: 12px;
  --text-xs-font-light-font-style: normal;
  --text-xs-font-light-font-weight: 300;
  --text-xs-font-light-letter-spacing: 0px;
  --text-xs-font-light-line-height: 130.0%;
  --text-xs-font-regular-font-family: "Inter-Regular", Helvetica;
  --text-xs-font-regular-font-size: 12px;
  --text-xs-font-regular-font-style: normal;
  --text-xs-font-regular-font-weight: 400;
  --text-xs-font-regular-letter-spacing: 0px;
  --text-xs-font-regular-line-height: 130.0%;
  --text-xs-font-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-xs-font-semibold-font-size: 12px;
  --text-xs-font-semibold-font-style: normal;
  --text-xs-font-semibold-font-weight: 600;
  --text-xs-font-semibold-letter-spacing: 0px;
  --text-xs-font-semibold-line-height: 130.0%;
  --white: #fff;
  --yellow-100: #fef9c3;
  --yellow-200: #fef08a;
  --yellow-300: #fde047;
  --yellow-400: #facc15;
  --yellow-50: #fefce8;
  --yellow-500: #eab308;
  --yellow-600: #ca8a04;
  --yellow-700: #a16207;
  --yellow-800: #854d0e;
  --yellow-900: #713f12;
}

#app {
  justify-content: center;
  display: flex;
}

.concept {
  border: 1px;
  flex-direction: column;
  align-items: center;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.concept .div {
  background-color: #fff;
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 80px;
  padding: 80px 112px;
  display: flex;
  position: relative;
}

.concept .frame {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 48px;
  width: 695px;
  display: flex;
  position: relative;
}

.concept .header {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  width: 804px;
  margin-left: -54.5px;
  margin-right: -54.5px;
  display: flex;
  position: relative;
}

.concept .text-wrapper {
  color: #111827;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Nanum Myeongjo, Helvetica;
  font-size: 48px;
  font-weight: 900;
  line-height: 46px;
  position: relative;
}

.concept .title {
  color: #111827;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-family: Nanum Myeongjo, Helvetica;
  font-size: 46px;
  font-weight: 900;
  line-height: 46px;
  position: relative;
}

.concept .title-2 {
  color: #111827;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-family: Noto Sans KR, Helvetica;
  font-size: 80px;
  font-weight: 900;
  line-height: 16px;
  position: relative;
}

.concept .title-3 {
  color: #111827;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-family: Nanum Myeongjo, Helvetica;
  font-size: 90px;
  font-weight: 900;
  line-height: 90px;
  position: relative;
}

.concept .images {
  background-color: #fff;
  background-image: url("ljs-0004-1.4b02568e.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 20px;
  width: 800px;
  height: 1200px;
  position: relative;
  overflow: hidden;
}

.concept .talkmedia-i {
  object-fit: cover;
  width: 800px;
  height: 1200px;
  position: absolute;
  top: 0;
  left: 0;
}

.concept .text {
  color: #111827;
  letter-spacing: .2px;
  text-align: center;
  align-self: stretch;
  font-family: Noto Sans KR, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.concept .div-2 {
  background-color: var(--gray-300);
  width: 1216px;
  height: 1px;
  position: relative;
}

.concept .p {
  color: #111827;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-family: Nanum Myeongjo, Helvetica;
  font-size: 48px;
  font-weight: 400;
  line-height: 67.2px;
  position: relative;
}

.concept .span {
  font-weight: 900;
}

.concept .text-wrapper-2 {
  color: #111827;
  letter-spacing: 0;
  font-family: Nanum Myeongjo, Helvetica;
  font-size: 48px;
  font-weight: 400;
  line-height: 67.2px;
}

.concept .frame-2 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 48px;
  width: 590px;
  display: flex;
  position: relative;
}

.concept .header-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.concept .title-4 {
  color: #111827;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Noto Sans KR, Helvetica;
  font-size: 48px;
  font-weight: 900;
  line-height: 67.2px;
  position: relative;
}

.concept .gallery {
  zoom: 2;
  width: 500px;
  height: 750px;
  margin-left: -425px;
  margin-right: -425px;
  position: relative;
}

.concept .area {
  background-color: #484848;
  width: 900px;
  height: 900px;
  margin-left: -425px;
  margin-right: -425px;
  position: relative;
}

.concept .header-wrapper {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 48px;
  display: inline-flex;
  position: relative;
}

.concept .header-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  width: 974px;
  display: flex;
  position: relative;
}

.concept .text-2 {
  color: var(--gray-500);
  letter-spacing: .2px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Noto Sans KR, Helvetica;
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.concept .title-5 {
  color: #111827;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-family: Noto Sans KR, Helvetica;
  font-size: 58px;
  font-weight: 900;
  line-height: 96px;
  position: relative;
}

.concept .map {
  zoom: 2;
  background-color: #484848;
  width: 450px;
  height: 450px;
  margin-left: -112px;
  margin-right: -112px;
  position: relative;
}

.concept .buttons {
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.concept .button {
  background-color: #20c55f;
  border-radius: 11.1px;
  justify-content: center;
  align-items: center;
  width: 262.7px;
  padding: 18.5px 37px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.concept .text-3 {
  color: var(--white);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1.85px;
  font-family: Noto Sans KR, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 37px;
  position: relative;
}

.concept .div-wrapper {
  background-color: #eab308;
  border-radius: 11.1px;
  justify-content: center;
  align-items: center;
  width: 262.7px;
  padding: 18.5px 37px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.concept .text-4 {
  color: #111827;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1.85px;
  font-family: Noto Sans KR, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 37px;
  position: relative;
}

.concept .button-2 {
  background-color: #4585e4;
  border-radius: 11.1px;
  justify-content: center;
  align-items: center;
  width: 262.7px;
  padding: 18.5px 37px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.concept .header-4 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  width: 926px;
  margin-left: -168px;
  margin-right: -168px;
  display: flex;
  position: relative;
}

.concept .row {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 32px;
  width: 1000px;
  display: flex;
  position: relative;
}

.concept .feature-card {
  background-color: #fff;
  border-radius: 6px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 1000px;
  padding: 32px 0;
  display: flex;
  position: relative;
}

.concept .icon {
  width: 64px;
  height: 64px;
  position: relative;
}

.concept .title-text {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.concept .text-5 {
  color: #111827;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Noto Sans KR, Helvetica;
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  position: relative;
}

.concept .text-6 {
  color: #111827;
  letter-spacing: .2px;
  text-align: center;
  align-self: stretch;
  font-family: Noto Sans KR, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.concept .text-wrapper-3 {
  font-weight: 500;
}

.concept .text-wrapper-4 {
  font-weight: 700;
}

.concept .image-wrapper {
  background-color: #fff;
  border-radius: 6px;
  width: 815px;
  height: 630px;
  position: relative;
  overflow: hidden;
}

.concept .image {
  object-fit: cover;
  width: 815px;
  height: 624px;
  position: absolute;
  top: 3px;
  left: 0;
}

.concept .frame-3 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 48px;
  width: 755px;
  display: flex;
  position: relative;
}

.concept .title-6 {
  color: #111827;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Noto Sans KR, Helvetica;
  font-size: 64px;
  font-weight: 900;
  line-height: 96px;
  position: relative;
}

.concept .text-7 {
  color: var(--gray-500);
  letter-spacing: .2px;
  text-align: center;
  align-self: stretch;
  font-family: Noto Sans KR, Helvetica;
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.concept .buttons-2 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.concept .button-31 {
  background-color: var(--blue-800);
  border-radius: 11.1px;
  justify-content: center;
  align-items: center;
  width: 815px;
  height: 90px;
  padding: 18.5px 37px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.concept .button-32 {
  background-color: var(--pink-600);
  border-radius: 11.1px;
  justify-content: center;
  align-items: center;
  width: 815px;
  height: 90px;
  padding: 18.5px 37px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.concept .text-8 {
  color: var(--white);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Noto Sans KR, Helvetica;
  font-size: 40px;
  font-weight: 700;
  line-height: 37px;
  position: relative;
}

.concept .button-4 {
  background-color: var(--gray-200);
  border-radius: 11.1px;
  justify-content: center;
  align-items: center;
  width: 815px;
  height: 90px;
  padding: 18.5px 37px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.concept .text-9 {
  color: #111827;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Noto Sans KR, Helvetica;
  font-size: 40px;
  font-weight: 700;
  line-height: 37px;
  position: relative;
}

.concept .button-5 {
  background-color: var(--yellow-500);
  border-radius: 11.1px;
  justify-content: center;
  align-items: center;
  width: 262.7px;
  padding: 18.5px 37px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.concept .text-10 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1.85px;
  font-family: Noto Sans KR, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 37px;
  position: relative;
}

.concept .button-6 {
  background-color: #111827;
  border-radius: 11.1px;
  justify-content: center;
  align-items: center;
  width: 262.7px;
  padding: 18.5px 37px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.concept .text-11 {
  color: var(--white);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1.85px;
  margin-left: -5.15px;
  margin-right: -5.15px;
  font-family: Noto Sans KR, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 37px;
  position: relative;
}

.concept .text-12 {
  color: var(--white);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1.85px;
  margin-left: -12.65px;
  margin-right: -12.65px;
  font-family: Noto Sans KR, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 37px;
  position: relative;
}

.concept .footer {
  mix-blend-mode: normal;
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 80px;
  padding: 80px 112px;
  display: flex;
  position: relative;
}

.concept .content {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.concept .img {
  width: 54.05px;
  height: 68.87px;
  position: relative;
}

.concept .text-13 {
  color: var(--gray-500);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Noto Sans KR, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  position: relative;
}

.buttons div, .buttons-2 div {
  cursor: pointer;
}

/*# sourceMappingURL=index.bc33e037.css.map */
